import React from "react";
import { Image } from "../../../common/shared-components/images";
import { Container, Content, Header, ImgHolder, Text } from "./styles";

import { useIntl } from "gatsby-plugin-react-intl";
import i18n from "../i18n";

const Start = () => {
  const intl = useIntl();
  return (
    <Container>
      <Content>
        <Header>{intl.formatMessage(i18n.headline.title)}</Header>
        <ImgHolder>
          <Image source="lotten.jpg" alt="board repair" />
        </ImgHolder>
        <Text>
          {intl.formatMessage(i18n.headline.description, {
            break: (str) => (
              <span>
                <br />
                {str}{" "}
              </span>
            ),
          })}
        </Text>
      </Content>
    </Container>
  );
};

export default Start;
