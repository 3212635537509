import { defineMessages } from "react-intl"

const scope = "board"

export default defineMessages({
  headline: {
    title: {
      id: `${scope}.title`,
      defaultMessage: "Mainboard-Reparatur",
    },
    description: {
      id: `${scope}.description`,
      defaultMessage:
        "Wir sind im Besitz von professionellem Equipment zum Löten von SMD-, BGA-, µBGA-, und CSP-Bauelementen, sowie der FLIP-CHIP-Technologie. Die Reparaturen führen wir mithilfe von erfahrenen Technikern durch, die eine große Leidenschaft für ihre Arbeit mitbringen.",
    },
  },
  graphics: {
    title: {
      id: `${scope}.title`,
      defaultMessage: "Einsatz neuer Grafikchips ",
    },
    description: {
      id: `${scope}.description`,
      defaultMessage:
        "Wir beschäftigen uns seit vielen Jahren mit der professionellen Reparatur von Grafikchips und Grafikkarten in Notebooks, PCs, All-in-One-Geräten und Industrie-Rechnern. <break>Ein thermischer Defekt aufgrund des fortgeschrittenen Lebensalters der Grafikeinheit fällt hierbei nicht unter die Herstellergarantie. Wir tauschen die Bauelemente unkompliziert aus und ermöglichen den Weiterbetrieb des Gerätes. </break>",
    },
  },
  check: {
    title: {
      id: `${scope}.title`,
      defaultMessage: "Prüfung von Chipsätzen",
    },
    description: {
      id: `${scope}.description`,
      defaultMessage:
        "Vor und nach der Reparatur prüfen wir Chipsätze im Rahmen einer High-End-Diagnose mithilfe modernster Mess- und Prüfgeräte. <break>Auf diese Weise bieten wir Ihnen ein Full-Service-Paket und kümmern uns umfassend um die Reparatur und Wartung Ihres Gerätes. </break><break>Dadurch ersparen Sie sich die Inanspruchnahme weiterer Dienstleistungsbetriebe und profitieren von einer Erstberatung inklusive Fehlerdiagnose direkt vor Ort. Gleichzeitig hilft uns die Chipsatz-Prüfung dabei, unsere hohen Qualitätsstandards zu wahren.</break>",
    },
  },
  change: {
    title: {
      id: `${scope}.title`,
      defaultMessage: "Wechsel einzelner Bauteile ",
    },
    description: {
      id: `${scope}.description`,
      defaultMessage:
        "Minim deserunt laboris irure ipsum commodo aliquip ullamco nostrud pariatur incididunt incididunt ex. Non consequat laborum cupidatat laborum dolore magna occaecat anim anim tempor eiusmod laboris dolor cillum. Consequat ut deserunt magna consequat aute sunt ut fugiat pariatur proident enim esse esse. Incididunt nisi elit aute et aute Lorem incididunt ut commodo ut cillum nisi do in.",
    },
  },
})
