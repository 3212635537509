import React from "react"
import Page from "../components/common/page"

import { ContentRow } from "../components/common/shared-components/layouts"
import ChipCheck from "../components/page-components/board/chip-check"
import NewGraphics from "../components/page-components/board/new-graphics"
import Start from "../components/page-components/board/start"



const CONTENT_ROWS = [
  [<Start />],
  [<NewGraphics />],
  [<ChipCheck />],
]

const Board = () => {
  return (
    <Page title="board">
      {CONTENT_ROWS.map((components, key) => (
        <ContentRow key={key} components={components} />
      ))}
    </Page>
  )
}

export default Board
