import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  background: #282828;
  color: white;
`

export const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`

export const Header = styled.h1`
  width: 100%;
  font-size: 2rem;

  font-family: "Open Sans";
  text-align: center;
  margin: 1.2rem auto;
  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
`

export const ImgHolder = styled.div``

export const Text = styled.p`
  max-width: 1000px;
  font-family: "Open Sans";
  font-weight: 300;
  padding: 2rem 0;
  margin: 2rem auto 4rem auto;
  font-size: 1.3rem;
  @media (max-width: 768px) {
    margin: 0 1.5rem;
  }
`
